import { useAppSelector, useAppDispatch } from '@hooks/redux-hooks';
import { playSound as _playSound } from '@slices/gameStateSlice';
import { SoundsDataKeys } from '../redux/store';

const useSound = () => {
  const { areSoundEffectsOn } = useAppSelector(s => s.gameState);
  const dispatch = useAppDispatch();

  const playSound = (sound: SoundsDataKeys) => {
    if (areSoundEffectsOn) {
      dispatch(_playSound({ sound }));
    }
  };
  return { playSound };
};

export default useSound;
